/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import convertInstructor from "Assets/Mascots/mascot-06.jpg";
import { useEffect, useState } from "react";
import { PageHeader } from "Common/PageHeader";
import { ChatWindow } from "Common/ChatWindow";
import { ChatBubble } from "Common/ChatBubble";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ConversionObj } from "Types/ConversionObj";
import { isFileTooBig, sleep } from "Helpers/functions";
import { ChatFileInput } from "Common/ChatFileInput";
import { fileToSummary } from "Interactors/YoutubeUrl.interactor";
import { sendAnalyticsEvent } from "Common/Analytics";

export const SummarizeFileToTxt = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [conversionObj, setConversionObj] = useState<ConversionObj>();
  const [userFile, setUserFile] = useState<File | null>();
  const [systemIsTyping, setSystemIsTyping] = useState<boolean>(false);
  const restartChatBubbles = [
    <ChatBubble content={t(`summarize-file.msg-02`)} />,
  ];
  const [chatBubbles, setChatBubbles] = useState<JSX.Element[]>([
    <ChatBubble content={t(`summarize-file.msg-01`)} />,
    ...restartChatBubbles,
  ]);

  useEffect(() => {
    if (userFile) {
      checkFileSize();
    }
  }, [userFile]);

  useEffect(() => {
    if (conversionObj?.summaryPath) displayDownloadLink();
  }, [conversionObj?.summaryPath]);

  const navigateWithTargetPath = (path: string) => {
    navigate({
      pathname: "/downloads",
      search: createSearchParams({
        downloadUrl: path,
      }).toString(),
    });
  };

  const checkFileSize = async () => {
    if (userFile) {
      if (isFileTooBig(userFile.size)) {
        await addMessage(t(`general.error-big-file`), true);
        restart();
      }
    }
  };

  const restart = () => {
    setSystemIsTyping(false);
    setUserFile(undefined);
    setConversionObj(undefined);
    setChatBubbles((prev) => [...prev, ...restartChatBubbles]);
  };

  const displayDownloadLink = async () => {
    if (!conversionObj?.summaryPath) return;
    await sleep(300);
    await addMessage(t(`general.success`), true);
    await sleep(300);
    setChatBubbles((prev) => [
      ...prev,
      <ChatBubble
        content={
          <span>
            {t(`summarize-file.msg-convert-success`)}
            <p
              className="download-link"
              onClick={() => {
                if (!conversionObj?.summaryPath) return;
                navigateWithTargetPath(conversionObj?.summaryPath);
              }}
            >
              {t(`general.download-summary`)}
            </p>
          </span>
        }
        isSystem
      />,
    ]);
    sendAnalyticsEvent({
      category: "file",
      action: "summary",
      fileName: conversionObj?.summaryPath,
    });
    setSystemIsTyping(false);
  };

  const addMessage = async (
    newMsg: string | JSX.Element,
    isSystem: boolean | undefined = false
  ) => {
    if (isSystem) await sleep(300);
    setChatBubbles((prev) => [
      ...prev,
      <ChatBubble content={newMsg} isSystem={isSystem} />,
    ]);
    const element = document.getElementById("scroll-ref");
    if (element) {
      window.scrollTo(0, window.scrollY - 1150);
    }
  };

  const uploadUserFile = async () => {
    if (!userFile) return;
    setSystemIsTyping(true);
    const msg = userFile?.name;
    setConversionObj((prev) => ({ ...prev, title: msg, url: msg }));
    const res = await fileToSummary(userFile);
    if (res.error || !res.data) {
      await addMessage(t(`general.error-wrong-try-again`), true);
      restart();
    } else {
      await addMessage(msg);
      setConversionObj((prev) => ({ ...prev, summaryPath: res.data }));
    }
    setSystemIsTyping(false);
  };

  return (
    <ConvertToMp3Wrapper>
      <PageHeader
        instructor={convertInstructor}
        title={t(`summarize-file.page-title`)}
      />
      <ChatWindow systemIsTyping={systemIsTyping} chatBubbles={chatBubbles} />
      <ChatFileInput
        userFile={userFile}
        setUserFile={setUserFile}
        uploadUserFile={uploadUserFile}
        restart={restart}
      />
    </ConvertToMp3Wrapper>
  );
};

const ConvertToMp3Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1100px) {
  }
`;
