import { styled } from "styled-components";
import { CHAT_BUTTON_SIZE, CHAT_INPUT_HEIGHT } from "Helpers/Constants";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { t } from "i18next";
import { AiOutlineSend } from "react-icons/ai";
import { RiLoopRightFill } from "react-icons/ri";

interface ChatInputProps {
  disabled?: boolean;
  videoUrl?: string;
  userMessage: string;
  setUserMessage: Dispatch<SetStateAction<string>>;
  sendUserMessage: () => void;
  restart: () => void;
}
export const ChatInput = ({
  disabled,
  videoUrl,
  userMessage,
  setUserMessage,
  sendUserMessage,
  restart,
}: ChatInputProps) => {
  return (
    <ChatInputWrapper>
      <input
        disabled={!!videoUrl || !!disabled}
        placeholder={t("general.your-message")}
        value={videoUrl ?? userMessage}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          !disabled && setUserMessage(e.target.value);
        }}
        style={{ height: CHAT_INPUT_HEIGHT }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !disabled) {
            sendUserMessage();
          }
        }}
      />
      {!videoUrl ? (
        <button onClick={sendUserMessage}>
          <AiOutlineSend
            className="send"
            size={CHAT_BUTTON_SIZE / 2}
            color="white"
            fill="white"
          />
        </button>
      ) : (
        <button onClick={restart}>
          <RiLoopRightFill
            className="loop"
            size={CHAT_BUTTON_SIZE / 2}
            color="white"
            fill="white"
          />
        </button>
      )}
    </ChatInputWrapper>
  );
};

const ChatInputWrapper = styled.section`
  height: ${CHAT_INPUT_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--white);
  z-index: 1;
  padding: 5px 20px;
  input {
    border: 2px solid var(--grey);
    max-height: 90%;
    padding: 0 10px;
    outline: 0;
    margin: 0;
    flex: 1;
    border-radius: 25px;
    &:disabled {
      background-color: grey;
    }
  }
  button {
    cursor: pointer;
    border: none;
    border-radius: 100%;
    background-color: var(--primary);
    flex-shrink: 0;
    flex-grow: 0;
    width: ${CHAT_BUTTON_SIZE}px;
    height: ${CHAT_BUTTON_SIZE}px;
    margin: 0;
    padding: 0;
    svg {
      transform: translate(0, 1px);
    }
  }
  @media only screen and (max-width: 1100px) {
    button {
      .loop {
        svg {
          transform: translate(1px, 2px);
        }
      }
      .send {
        svg {
          transform: translate(2px, 3px);
        }
      }
    }
  }
`;
