/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { AppButton } from "../../Common/AppButton";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  startDownload,
  getServerFileInfo,
} from "Interactors/YoutubeUrl.interactor";
import fileDownload from "js-file-download";
import { PageHeader } from "Common/PageHeader";
import { AxiosProgressEvent } from "axios";
import { bytesToMB } from "Helpers/functions";
import { ProgressBar } from "Common/ProgressBar";
import { TargetFileInfo } from "Types/TargetFileInfo";
import { sendAnalyticsEvent } from "Common/Analytics";

export const Downloads = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [downloadMessage, setDownloadMessage] = useState<string>("");
  const [downloadedMb, setDownloadedMb] = useState<number>(0);
  const [localFileInfo, setLocalFileInfo] = useState<TargetFileInfo>();
  const searchParams = new URLSearchParams(location.search);
  const paramSearchQuery = searchParams.get("downloadUrl");

  useEffect(() => {
    getFileInfo();
  }, [paramSearchQuery]);

  const getFileInfo = async () => {
    if (paramSearchQuery) {
      try {
        const res = await getServerFileInfo(paramSearchQuery);
        if (!!res?.data?.size) {
          setLocalFileInfo(res.data);
        }
      } catch (error) {}
    }
  };

  const onDownloadProgress = async (progressEvent: AxiosProgressEvent) => {
    setDownloadedMb(progressEvent?.loaded ?? 0);
  };
  const downloadFile = async () => {
    setDownloadMessage(t(`downloads-page.download-started`));
    const res = await startDownload(paramSearchQuery ?? "", onDownloadProgress);
    if (res?.data?.error) {
      if (res?.data?.error === "404") {
        setDownloadMessage(t(`downloads-page.error-file-not-exist`));
        setDownloadedMb(0);
      } else {
        setDownloadMessage(t(`general.error-wrong-try-again`));
      }
    } else {
      localFileInfo?.name && fileDownload(res, localFileInfo?.name);
      setDownloadMessage(t(`downloads-page.download-finished`));
      sendAnalyticsEvent({
        category: "download",
        action: "download",
        fileName: localFileInfo?.name ?? paramSearchQuery ?? "-",
      });
    }
  };

  return (
    <DownloadsWrapper>
      <PageHeader title={t(`downloads-page.title`)} />
      <div className="content">
        {localFileInfo?.name ? (
          <>
            <h2 className="subtitle">
              <span>{t("downloads-page.subtitle") + " "}</span>
              {localFileInfo?.name}{" "}
              {localFileInfo && `-  ${bytesToMB(localFileInfo.size)} Mb`}
            </h2>
            <AppButton
              text={t("downloads-page.button")}
              width={300}
              action={downloadFile}
            />
          </>
        ) : paramSearchQuery ? (
          <p>{t("downloads-page.error-file-not-exist")}</p>
        ) : (
          <p>{t("downloads-page.nothing-to-download")}</p>
        )}
        {!!localFileInfo?.size && downloadedMb !== 0 && (
          <div className="progress-container">
            <ProgressBar progress={downloadedMb} total={localFileInfo?.size} />
          </div>
        )}
        {downloadedMb > 0 && (
          <span>
            {t(`downloads-page.downloaded`)}
            {bytesToMB(downloadedMb)}Mb
          </span>
        )}
        <h3 className="download-started">{downloadMessage}</h3>

        {downloadMessage === t(`downloads-page.download-finished`) && (
          <Link to={"/"}>{t(`downloads-page.convert-another`)}</Link>
        )}
      </div>
    </DownloadsWrapper>
  );
};

const DownloadsWrapper = styled.div`
  background-color: var(--light-grey);
  width: 100%;
  height: 100%;
  padding: 0;
  .content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    .progress-container {
      width: 100%;
      max-width: 300px;
      min-height: 10px;
    }
  }
  .subtitle {
    font-size: 18px;
    span {
      font-weight: 500;
    }
  }
  a {
    color: var(--primary);
  }
  @media only screen and (max-width: 1100px) {
  }
`;
