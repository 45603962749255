import { styled } from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { PageNotFound } from "./PageNotFound";
import { routerPages } from "../Helpers/RouteConfig";
import { SideBar } from "./SideBar";
import { ConvertUrlToMp3 } from "Pages/Conversations/ConvertUrlToMp3";

export const Navigator = () => {
  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.pathname]);

  return (
    <NavigatorWrapper>
      <SideBar />
      <div className="main-content">
        <Routes>
          <Route index element={<ConvertUrlToMp3 />} />
          {routerPages.map((page, i) => (
            <Route key={`route-${i}`} path={page.path} element={page.element} />
          ))}

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      {/* <CookiesBanner /> */}
    </NavigatorWrapper>
  );
};
const NavigatorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100vw;
  background-color: var(--white);

  .main-content {
    flex: 1;
  }
  @media only screen and (max-width: 1100px) {
  }
`;
