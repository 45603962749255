/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import convertInstructor from "Assets/Mascots/mascot-05.jpg";
import { useEffect, useState } from "react";
import {
  getYoutubeUrlInfo,
  getYtSummary,
} from "Interactors/YoutubeUrl.interactor";
import { PageHeader } from "Common/PageHeader";
import { ChatWindow } from "Common/ChatWindow";
import { ChatBubble } from "Common/ChatBubble";
import { ChatInput } from "Common/ChatInput";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ConversionObj } from "Types/ConversionObj";
import { isValidYoutubeLink, sleep } from "Helpers/functions";
import { ERROR_TOO_LONG } from "Helpers/Constants";
import { sendAnalyticsEvent } from "Common/Analytics";

export const SummarizeUrlToTxt = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [conversionObj, setConversionObj] = useState<ConversionObj>();
  const [userMessage, setUserMessage] = useState<string>("");
  const [systemIsTyping, setSystemIsTyping] = useState<boolean>(false);
  const restartChatBubbles = [
    <ChatBubble content={t(`summarize-url.msg-02`)} />,
  ];
  const [chatBubbles, setChatBubbles] = useState<JSX.Element[]>([
    <ChatBubble content={t(`summarize-url.msg-01`)} />,
    ...restartChatBubbles,
  ]);

  useEffect(() => {
    if (!!conversionObj?.url && !conversionObj?.title) {
      getYoutubeInfo(conversionObj.url);
    }
  }, [conversionObj?.url, conversionObj?.title]);

  useEffect(() => {
    if (conversionObj?.summaryPath) displayDownloadLink();
  }, [conversionObj?.summaryPath]);

  const getYoutubeInfo = async (url: string) => {
    if (!conversionObj?.url || !!conversionObj?.title) return;
    setSystemIsTyping(true);
    const res = await getYoutubeUrlInfo(conversionObj.url);
    if (res.error) {
      if (res?.error?.message) {
        await addMessage(res.error.message, true);
      } else {
        await addMessage(t(`general.error-wrong-try-again`), true);
      }
      await sleep(300);
      restart();
    } else {
      const newTitle = res.data;
      setConversionObj((prev) => ({ ...prev, title: newTitle }));
      await addMessage(
        t(`summarize-url.msg-convert-received`) + " " + newTitle,
        true
      );
      startSummary();
    }
  };

  const navigateWithTargetPath = (path: string) => {
    navigate({
      pathname: "/downloads",
      search: createSearchParams({
        downloadUrl: path,
      }).toString(),
    });
  };

  const restart = () => {
    setSystemIsTyping(false);
    setConversionObj(undefined);
    setChatBubbles((prev) => [...prev, ...restartChatBubbles]);
  };

  const startSummary = async () => {
    if (!conversionObj?.url) return;
    const res = await getYtSummary(conversionObj.url);
    if (res.error || !res.data) {
      if (res.message === ERROR_TOO_LONG) {
        await addMessage(t(`general.error-long-text`), true);
      } else {
        await addMessage(t(`general.error-wrong-try-again`), true);
      }
      restart();
    } else {
      setConversionObj((prev) => ({ ...prev, summaryPath: res.data }));
    }
    setSystemIsTyping(false);
  };

  const displayDownloadLink = async () => {
    if (!conversionObj?.summaryPath) return;
    await sleep(300);
    await addMessage(t(`general.success`), true);
    await sleep(300);
    setChatBubbles((prev) => [
      ...prev,
      <ChatBubble
        content={
          <span>
            {t(`summarize-url.msg-convert-success`)}
            <p
              className="download-link"
              onClick={() => {
                if (!conversionObj?.summaryPath) return;
                navigateWithTargetPath(conversionObj?.summaryPath);
              }}
            >
              {conversionObj?.title}
            </p>
          </span>
        }
        isSystem
      />,
    ]);
    sendAnalyticsEvent({
      category: "url",
      action: "summary",
      fileName: conversionObj?.summaryPath,
    });
    setSystemIsTyping(false);
  };

  const addMessage = async (
    newMsg: string | JSX.Element,
    isSystem: boolean | undefined = false
  ) => {
    if (isSystem) await sleep(300);
    setChatBubbles((prev) => [
      ...prev,
      <ChatBubble content={newMsg} isSystem={isSystem} />,
    ]);
    const element = document.getElementById("scroll-ref");
    if (element) {
      window.scrollTo(0, window.scrollY - 1150);
    }
  };

  const sendUserMessage = async () => {
    const msg = userMessage;
    setUserMessage("");
    if (!msg) return;
    await addMessage(msg);
    if (!isValidYoutubeLink(msg)) {
      await addMessage(t(`general.invalid-yt-url`), true);
      return;
    }
    if (!conversionObj?.url) {
      setConversionObj((prev) => ({ ...prev, url: msg }));
    }
  };

  return (
    <ConvertToMp3Wrapper>
      <PageHeader
        instructor={convertInstructor}
        title={t(`summarize-url.page-title`)}
      />

      <ChatWindow systemIsTyping={systemIsTyping} chatBubbles={chatBubbles} />
      <ChatInput
        videoUrl={conversionObj?.url}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        sendUserMessage={sendUserMessage}
        restart={restart}
      />
    </ConvertToMp3Wrapper>
  );
};

const ConvertToMp3Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1100px) {
  }
`;
