import { styled } from "styled-components";
import { Avatar } from "./Avatar";
import { CONVO_IMG_WIDTH, headerHeight } from "Helpers/Constants";
import { SideBar } from "./SideBar";
import { useState } from "react";
import { IoMdMenu } from "react-icons/io";

interface PageHeaderProps {
  instructor?: string;
  title: string;
}
export const PageHeader = ({ instructor, title }: PageHeaderProps) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  return (
    <PageHeaderWrapper>
      <button className="sidebar-opener" onClick={() => setSidebarIsOpen(true)}>
        <IoMdMenu fill="white" color="red" size={CONVO_IMG_WIDTH} />
      </button>
      {instructor && <Avatar img={instructor} clickable />}
      <h1>{title}</h1>
      <div
        className={`closer ${sidebarIsOpen ? "active" : ""}`}
        onClick={() => setSidebarIsOpen(false)}
      />

      <div
        className={`sidebar-outter-container ${sidebarIsOpen ? "active" : ""}`}
      >
        <SideBar />
      </div>
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 0 10px;
  background-color: var(--dark);
  height: ${headerHeight}px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  .sidebar-opener {
    display: none;
    width: ${CONVO_IMG_WIDTH}px;
    height: ${CONVO_IMG_WIDTH}px;
    border: none;
    background: none;

    justify-content: center;
    align-items: center;
    border-radius: 100%;
    svg {
      color: white;
      fill: white;
    }
  }

  .insructor-container {
    width: 10%;
  }
  h1 {
    flex: 1;
    color: var(--white);
  }
  .sidebar-outter-container {
    display: none;
  }
  @media only screen and (max-width: 1100px) {
    .sidebar-opener {
      display: flex;
    }
    .closer {
      position: fixed;
      &.active {
        background-color: var(--bg-transparent);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .sidebar-outter-container {
      display: block;
      position: relative;
      z-index: 30;
      position: fixed;
      top: 0;
      left: -100%;
      width: 80vw;
      height: 100vh;
      overflow: hidden;
      transition: all 300ms ease;
      &.active {
        left: 0%;
      }
      .sidebar-container {
        z-index: 30;
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
