import env from "../env.json";
import endpoints from "../endpoints.json";
import axios, { AxiosProgressEvent } from "axios";

export const getServerFileInfo = async (filepath: string) => {
  try {
    const endpoint = `${
      env.backendUrl + endpoints.getServerFileInfo
    }?filepath=${filepath}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getYoutubeUrlInfo = async (url: string) => {
  try {
    const endpoint = `${env.backendUrl + endpoints.ytUrlInfo}?url=${url}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
    return { error: true };
  }
};

export const getMp3 = async (url: string) => {
  try {
    const endpoint = `${env.backendUrl + endpoints.ytUrlToMp3}?url=${url}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
    return { error: true };
  }
};

export const getTranscript = async (url: string) => {
  try {
    const endpoint = `${env.backendUrl + endpoints.transcribeYtUrl}?url=${url}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
    return { error: true };
  }
};

export const startDownload = async (
  url: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const endpoint = `${
      env.backendUrl + "downloads?filepath=" + encodeURIComponent(url)
    }`;
    const response = await axios.get(endpoint, {
      responseType: "blob",
      onDownloadProgress,
    });
    return response?.data;
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: "404" } };
    }
    return { data: { error: true } };
  }
};

export const startConvertVideoFileToAudio = async (file: File) => {
  try {
    const endpoint = `${env.backendUrl}${endpoints.file2Mp3}`;
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("file", file);
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: "404" } };
    }
    return { error: true };
  }
};

export const startConvertVideoFileToTxt = async (file: File) => {
  try {
    const endpoint = `${env.backendUrl}${endpoints.file2Txt}`;
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("file", file);
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: "404" } };
    }
    return { error: true };
  }
};

export const getYtSummary = async (url: string) => {
  try {
    const endpoint = `${env.backendUrl + endpoints.yt2Summary}?url=${url}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
    return { error: true };
  }
};

export const fileToSummary = async (file: File) => {
  try {
    const endpoint = `${env.backendUrl}${endpoints.file2Summary}`;
    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("file", file);
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: "404" } };
    }
    return { error: true };
  }
};
