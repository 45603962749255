import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { AnalyticsEvent } from "Types/AnalyticsEvent";
// import { isDev } from "App";

ReactGA.initialize("G-0M9E8JKSPC");

const sendAnalyticsPageView = (page: string) => {
  // !isDev &&
  ReactGA.send({
    hitType: "pageview",
    page: page,
  });
};
export const sendAnalyticsEvent = (event: AnalyticsEvent) => {
  // !isDev &&
  ReactGA.event({
    category: event.category,
    action: event.category,
    label: event.fileName,
  });
};

export const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    sendAnalyticsPageView(location.pathname);
  }, [location.pathname]);

  return <></>;
};
