import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { PageHeader } from "Common/PageHeader";

export const Tos = () => {
  const { t } = useTranslation();
  return (
    <TosWrapper>
      <PageHeader title={t(`terms.title`)} />
      <div className="content">
        {/* <h1>Terms of Service</h1> */}
        <h2>Introduction:</h2>
        <p>
          Welcome to YouTranscribe, where efficiency and professionalism
          converge. Before engaging with our suite of services, which includes
          YouTranscribe.com, the YouTranscribe application, and the
          YouTranscribe browser extension, we invite you to familiarize yourself
          with our Terms of Service.
        </p>
        <h2>1. Service Overview:</h2>
        <p>
          This section articulates the governing Terms under which
          YouTranscribe.com ("we" or "us") extends its services. Defined
          collectively as the "Service," this encompasses our website,
          applications, and related services. In these Terms, "you" or "your"
          denotes not just an individual but includes entities represented by
          you, your representatives, successors, assigns, affiliates, and
          associated devices.
        </p>
        <h3>Usage Agreement:</h3>
        <p>
          By engaging with the Service—through visiting, accessing, using,
          downloading, copying, installing, or joining—you explicitly express
          your understanding and acceptance of these Terms. If, however, these
          Terms do not align with your perspective, we kindly request that you
          discontinue the use of the Service and delete any copies in your
          possession.
        </p>
        <h2>2. Risk and Liabilities:</h2>
        <p>
          This section outlines specific liability limitations and legal
          disclaimers. In essence, your use of the Service is at your own risk.
          We, as the service providers, do not assume any liability or provide
          warranties, whether express or implied, regarding the Service.
        </p>
        <h2>3. Eligibility and Accounts:</h2>
        <h3>Legal Compliance and Age Requirement:</h3>
        <p>
          In the realm of eligibility and accounts, we emphasize that the use of
          the Service is prohibited where it conflicts with the law. You confirm
          your absence in jurisdictions under international embargo or labeled
          as "terrorist supporting" by applicable laws. Additionally, the
          Service is exclusively available to individuals aged eighteen (18) and
          above.
        </p>
        <h3>Account Management and Responsibilities:</h3>
        <p>
          Account creation, when required, necessitates accurate and complete
          information. Unauthorized access to other accounts is strictly
          prohibited. You are solely responsible for maintaining account
          confidentiality, promptly notifying us of any breaches or unauthorized
          use.
        </p>
        <h3>Consideration for Acceptance:</h3>
        <p>
          Concluding this section, your acknowledgment of these Terms is the
          consideration for us granting you the right to use the Service. Your
          understanding of this exchange is essential.
        </p>
        <p>
          In your journey through YouTranscribe, may these Terms serve as a
          guide, ensuring a seamless and secure experience.
        </p>

        <h2>2. Grant of Use</h2>

        <p>
          Under these Terms, we provide you with a non-exclusive,
          non-transferable, and limited right to access, non-publicly display,
          and use the Service. This includes all content available on the
          Service (referred to as the “Content”), accessible on your computer or
          mobile device. Your usage should align with these Terms and adhere to
          the specified restrictions, both technical and otherwise, imposed by
          the Service. It is important to note that access and use of the
          Service are granted solely for personal and non-commercial purposes.
        </p>

        <p>
          This grant is subject to termination at our discretion and for any
          reason, with or without prior notice. Upon termination, we reserve the
          right to:
        </p>

        <ol>
          <li>Delete or deactivate your Account or use of the Service.</li>
          <li>
            Block your e-mail and/or IP addresses, terminating your ability to
            use the Service.
          </li>
          <li>
            Remove and/or delete any of your User Submissions (defined below).
          </li>
        </ol>

        <p>
          It is understood that your use of the Service post-termination is
          prohibited. The termination of your right to use the Service, however,
          does not affect the survival of other provisions in these Terms. You
          acknowledge that we bear no responsibility to you or any third party
          for the termination of your grant of use.
        </p>

        <p>
          Additionally, your use of the Service is bound by its rules, features,
          and technical restrictions, which may be subject to changes at our
          sole discretion. You are prohibited from attempting to use the Service
          in any manner not intended or permitted by its design and purpose.
        </p>

        <h2>3. Intellectual Property</h2>

        <p>
          a. The Content, excluding User Submissions and Third Party Content
          (defined below), but including other text, graphical images,
          photographs, music, video, software, scripts, source code, and
          trademarks, service marks, and logos contained therein (collectively
          “Proprietary Materials”), are owned by and/or licensed to us. All
          Proprietary Materials are subject to copyright, trademark, and/or
          other rights under the laws of applicable jurisdictions, including
          domestic laws, foreign laws, and international conventions. We reserve
          all our rights over our Proprietary Materials.
        </p>

        <p>
          Except as otherwise explicitly permitted, you agree not to copy,
          modify, publish, transmit, distribute, participate in the transfer or
          sale of, create derivative works of, or in any other way exploit, in
          whole or in part, any Proprietary Materials or Third Party Content.
        </p>

        <h2>4. User Submissions</h2>

        <p>
          a. You are entirely responsible for any and all materials you
          download, upload, submit, transmit, create, modify or otherwise make
          available in or via the Service, including any sound files, video
          files, or photographs that you create, modify, transmit or download
          through the Service (collectively, “User Submissions”). User
          Submissions cannot always be withdrawn. You acknowledge that any
          disclosure of personal information in User Submissions may make you
          personally identifiable and that we do not guarantee any
          confidentiality with respect to User Submissions.
        </p>

        <p>
          b. You shall be solely responsible for any and all of your own User
          Submissions and any and all consequences of downloading, uploading,
          submitting, modifying, transmitting, creating or otherwise making
          available the User Submissions. For any and all of your User
          Submissions, you affirm, represent and warrant that:
        </p>

        <ol type="i">
          <li>
            You own or have the necessary licenses, permissions, rights, or
            consents to use and authorize us to use all trademarks, copyrights,
            trade secrets or other proprietary rights in and to User Submissions
            for any and all uses contemplated by the Service and these Terms;
            and
          </li>
          <li>
            You have written consent, release, and/or permission from each and
            every identifiable individual in the User Submission to use the name
            and/or likeness of each and every such identifiable individual to
            enable use of the User Submission for any and all uses contemplated
            by the Services and these Terms.
          </li>
        </ol>

        <p>
          c. You further agree that you shall not download, upload, submit,
          create, transmit, modify, or otherwise make available material that:
        </p>

        <ol type="i">
          <li>
            Is copyrighted, protected by trade secret or trademark laws, or
            otherwise subject to third party proprietary rights, including
            privacy and publicity rights, unless you are the owner of such
            rights, have explicit permission from the rightful owner to submit
            the material and to grant us all of the license rights granted
            herein, or have other legal and effective basis to the material and
            to grant us all of the license rights granted herein;
          </li>
          <li>
            Is obscene, vulgar, illegal, unlawful, defamatory, fraudulent,
            libelous, harmful, harassing, abusive, threatening, invasive of
            privacy or publicity rights, hateful, racially or ethnically
            offensive, inflammatory, or otherwise inappropriate as decided by us
            in our sole discretion;
          </li>
          <li>
            Depicts illegal activities, promotes or depicts physical harm or
            injury against any group or individual, or promotes or depicts any
            act of cruelty to animals;
          </li>
          <li>
            Impersonates any person or entity or otherwise misrepresents you in
            any way, including creating a false identity;
          </li>
          <li>
            Would constitute, encourage or provide instructions for a criminal
            offense, a violation of the rights of any party, or that would
            otherwise create liability or violate any local, state, national or
            international law; or
          </li>
          <li>
            Is unsolicited or unauthorized advertising, promotion, “spam” or any
            other form of solicitation.
          </li>
        </ol>

        <p>
          We claim no ownership or control over User Submissions or Third Party
          Content. You or a third-party licensor, as appropriate, retain all
          copyrights to User Submissions and you are responsible for protecting
          those rights as appropriate. You irrevocably grant us a world-wide,
          non-exclusive, royalty-free, perpetual, non-cancelable,
          sub-licenseable license to reproduce, publicly perform, publicly
          display, distribute, adapt, modify, publish, translate, create
          derivative works of and otherwise exploit User Submissions for any
          purpose, including without limitation any purpose contemplated by the
          Service and these Terms. You also irrevocably waive and cause to be
          waived against us and any of our users any claims and assertions of
          moral rights or attribution with respect to User Submissions.
        </p>

        <p>
          You represent and warrant that you have all the rights, power and
          authority necessary to grant the rights granted herein to User
          Submissions. Specifically, you represent and warrant that you have the
          right to download, upload, modify, access, transmit, create or
          otherwise make available the User Submissions on the Service, and that
          downloading or uploading the User Submissions will not infringe upon
          any other party’s rights or your contractual obligations to other
          parties.
        </p>

        <p>
          f. You acknowledge that we may at our sole discretion refuse to
          publish, remove, or block access to any User Submission for any
          reason, or for no reason at all, with or without notice.
        </p>

        <p>
          Without limiting the other indemnification provisions herein, you
          agree to defend us against any claim, demand, suit or proceeding made
          or brought against us by a third-party alleging that your User
          Submissions or your use of the Service in violation of these Terms
          infringes or misappropriates the intellectual property rights of any
          third-party or violates applicable law and you shall indemnify us for
          any and all damages against us and for reasonable attorney’s fees and
          other costs incurred by us in connection with any such claim, demand,
          suit or proceeding.
        </p>

        <p>
          If you make any suggestions to us about improving the Service or
          adding new features to the Service, you are assigning to us the right
          to use your suggestions without any compensation to you.
        </p>

        <h2>5. Content on the Service</h2>

        <p>
          a. You understand and acknowledge that, when using the Service, you
          will be exposed to content from a variety of sources, including
          content made available on or through the Service by other users,
          services, parties, and through automated or other means (collectively,
          “Third Party Content”). We do not control and are not responsible for
          any Third Party Content. You understand and acknowledge that you may
          be exposed to content that is inaccurate, offensive, indecent, or
          otherwise objectionable or may cause harm to your computer systems.
          Without limiting the other limitation of liability provisions herein,
          you agree to waive, and hereby do waive, any legal or equitable rights
          or remedies you may have against us with respect thereto.
        </p>

        <p>
          b. We claim no ownership or control over Third Party Content. Third
          parties retain all rights to their respective Third Party Content, and
          they are responsible for protecting their rights as appropriate.
        </p>

        <p>
          c. You understand and acknowledge that we assume no responsibility
          whatsoever for monitoring the Service for inappropriate content or
          conduct. If, at any time, we choose, in our sole discretion, to
          monitor such content, we assume no responsibility for such content,
          have no obligation to modify or remove any such content (including
          User Submissions and Third Party Content), and assume no
          responsibility for the conduct of others submitting any such content
          (including User Submissions and Third Party Content).
        </p>

        <p>
          d. Without limiting the provisions below on limitations of liability
          and disclaimers of warranties, all Content (including User Submissions
          and Third Party Content) on the Service is provided to you “AS-IS” for
          your information and personal use only. You shall not use, copy,
          reproduce, distribute, transmit, broadcast, display, sell, license, or
          otherwise exploit for any other purpose whatsoever the Content without
          the prior written consent of the respective owners/licensors of the
          Content.
        </p>

        <p>
          e. You acknowledge that we may, at our sole discretion, refuse to
          publish, remove, or block access to any Content for any reason, or for
          no reason at all, with or without notice.
        </p>

        <h2>6. User Conduct</h2>

        <p>
          a. You represent and warrant that all the information and content
          provided by you to us is accurate and current and that you have all
          necessary rights, power, and authority to (i) agree to these Terms,
          (ii) provide the User Submissions to us, and (iii) perform the acts
          required of you under these Terms.
        </p>

        <p>
          b. You hereby expressly authorize us to monitor, record and log any of
          your activities on the Service.
        </p>

        <p>c. As a condition of your use of the Service:</p>

        <ol type="i">
          <li>
            You agree not to use the Service for any unlawful purpose or in any
            way that is prohibited by these Terms;
          </li>
          <li>
            You agree to abide by all applicable local, state, national and
            international laws and regulations;
          </li>
          <li>
            You agree not to use the Service in any way that exposes us to
            criminal or civil liability;
          </li>
          <li>
            You agree that you are solely responsible for all acts and omissions
            that occur as a result of your use of the Service;
          </li>
          <li>
            You agree that all your User Submissions belong to you and that you
            have the right and authority to provide them to us and make use of
            them on or through the Service;
          </li>
          <li>
            You agree not to use any automated means, including robots, crawlers
            or data mining tools, to download, monitor or use data or Content
            from the Service;
          </li>
          <li>
            You agree not to take any action that imposes, or may impose, in our
            sole discretion, an unreasonable or disproportionately large load on
            our technology infrastructure or otherwise make excessive demands on
            it;
          </li>
          <li>
            You agree not to “stalk” or otherwise harass anyone on or through
            the Service;
          </li>
          <li>
            You agree not to forge headers or otherwise manipulate identifiers
            in order to disguise the origin of any information you transmit;
          </li>
          <li>
            You agree not to disable, circumvent, or otherwise interfere with
            security related features of the Service or features that prevent or
            restrict use or copying of any content or which enforce limitations
            on the use of the Service or the content therein;
          </li>
          <li>
            You agree not to post, link to, or otherwise make available on the
            Service any material that contains software viruses or any computer
            code, file or program designed to interrupt, destroy, limit or
            monitor the functionality of any computer software or hardware or
            any telecommunications equipment;
          </li>
          <li>
            You agree not to license, sublicense, sell, resell, transfer,
            assign, distribute or otherwise in any way commercially exploit or
            make available the Service or any Content to any third party;
          </li>
          <li>You agree not to “frame” or “mirror” the Service; and</li>
          <li>You agree not to reverse engineer any portion of the Service.</li>
        </ol>

        <p>
          You may only use the Service consistent with these Terms and any and
          all agreements under which the Service is provided to you (including,
          for example, any app store agreement). You assume sole responsibility
          for obtaining any additional or related hardware or software required
          for use of the Service.
        </p>

        <p>
          Except as described below, you are not permitted to decompile,
          disassemble, reverse compile, reverse assemble, reverse translate or
          otherwise reverse engineer the Service, use any similar means to
          discover the source code of the Service or to discover any trade
          secrets or other intellectual property in the Service.
        </p>

        <p>
          You acknowledge that from time to time the Service may automatically
          check for and install updates on your device. You agree and accept
          that the Service may make updates without your confirmation or
          consent. Any updates to the Service will be deemed part of the
          Service. However, we have no obligation to provide you with any
          updates to the Service (nor does any third-party).
        </p>

        <p>
          We reserve the right to take appropriate action against any user for
          any unauthorized use of the Service, including civil, criminal and
          injunctive redress and the termination of any user’s use of the
          Service. Any use of the Service and our computer systems not
          authorized by these Terms is a violation of these Terms and certain
          international, foreign, and domestic criminal and civil laws.
        </p>

        <p>
          In addition to termination of the grant of use of the Service, any
          violation of this Agreement, including the provisions of this Section
          6, shall subject you to liquidated damages of ten thousand dollars
          ($10,000) for each violation. In the event that your violation results
          in legal action (whether against you or against us by any party) or
          physical or emotional harm to any party, you shall be subject to
          liquidated damages of One Hundred and Fifty Thousand Dollars
          ($150,000) for each violation. We may, in our discretion, assign any
          such damage claim or portion thereof to a third party that has been
          wronged by your conduct. These liquidated damages provisions are not a
          penalty, but instead an attempt by the Parties to reasonably ascertain
          the amount of actual damage that could occur from such a violation.
          You acknowledge and agree that the amount of these liquidated damages
          is a minimum and that if actual damages are greater you shall be
          liable for the greater amount. If a court of competent jurisdiction
          finds that these liquidated damages are unenforceable to any extent,
          then the liquidated damages shall be lowered only by the extent
          necessary for them to be enforceable.
        </p>

        <h2>7. Services on the Service</h2>

        <p>
          a. You recognize that the Service serves as a versatile tool.
          Specifically, but not exclusively, it enables you to access media on
          various platforms and, among other functions, download and/or convert
          that media. Usage of the Service must adhere to legal standards. We do
          not endorse, support, induce, or permit any use of the Service that
          may infringe on any law. Downloading content in violation of copyright
          laws is expressly prohibited.
        </p>

        <p>
          b. Should we store any User Submissions, our intention is not to
          retain them for more than a brief period, allowing users the
          opportunity to download their content.
        </p>

        <h2>8. Fees</h2>

        <p>
          a. You acknowledge our right to impose charges for any or all of our
          services and to modify our fees at our sole discretion. If we
          terminate your rights to use the Service due to a breach of these
          Terms, you are not entitled to a refund of any portion of your fees.
          Any fees are subject to additional rules, terms, conditions, or
          agreements posted on the Service or imposed by sales agents or payment
          processing companies, as may be amended from time to time.
        </p>

        <h2>9. Privacy Policy</h2>

        <p>
          a. We maintain a separate Privacy Policy, and your acceptance of these
          Terms implies that you have read and understood the Privacy Policy. We
          reserve the right to amend the Privacy Policy by posting such
          amendments to the Service or our website. No other notification will
          be provided regarding any amendments. Your continued use of the App
          following such changes will be considered your acknowledgment of the
          Privacy Policy and your understanding of its contents.
        </p>

        <p>
          b. You acknowledge our right to collect and use technical data and
          related information, including but not limited to technical
          information about your device, system, and application software, and
          peripherals, gathered periodically to facilitate the provision of
          updates to the Service.
        </p>

        <p>
          c. You understand, acknowledge, and agree that we may access,
          preserve, and disclose your information if required to do so by law or
          in a good faith belief that such access, preservation, or disclosure
          is reasonably desirable or necessary for us.
        </p>

        <h2>10. Copyright Claims</h2>

        <p>
          a. We value the intellectual property rights of others and prohibit
          the infringement of copyright, trademark, or other proprietary
          informational rights of any party. In our sole discretion, we may
          remove any Content or restrict the use of the Service with any Content
          that we believe violates the intellectual property rights of others.
          Submission of such Content may result in the termination of your use
          of the Service.
        </p>

        <p>
          <strong>Repeat Infringer Policy:</strong> As part of our policy on
          repeat infringement, any user for whose material we receive three
          good-faith and effective complaints within any contiguous six-month
          period will have their grant of use of the Service terminated.
        </p>

        <p>
          c. While we are not subject to United States law, we voluntarily
          comply with the Digital Millennium Copyright Act. Pursuant to Title
          17, Section 512(c)(2) of the United States Code, if you believe that
          any of your copyrighted material is being infringed on the Service, we
          have designated an agent to receive notifications of claimed copyright
          infringement. Notifications should be emailed to
          contact@YouTranscribe.com.
        </p>

        <p>
          d. All notifications not relevant to us or ineffective under the law
          will receive no response or action. An effective notification of
          claimed infringement must be a written communication to our agent that
          includes substantially the following:
        </p>

        <ol type="i">
          <li>
            Identification of the copyrighted work believed to be infringed,
            including a description and, where possible, a copy or the location
            (e.g., a URL) of an authorized version;
          </li>
          <li>
            Identification of the material believed to be infringing, its
            location, or, for search results, identification of the reference or
            link to the material or activity claimed to be infringing;
          </li>
          <li>
            Contact information, including address, telephone number, and, if
            available, email address;
          </li>
          <li>
            A statement of good faith belief that the use of the material is not
            authorized by the owner, agent, or the law;
          </li>
          <li>
            A statement that the information in the notification is accurate
            and, under penalty of perjury, that you are the owner or authorized
            to act on behalf of the owner;
          </li>
          <li>
            A physical or electronic signature from the copyright holder or an
            authorized representative.
          </li>
        </ol>

        <p>
          e. If your User Submission or content is removed due to a notification
          of claimed copyright infringement, you may provide us with a
          counter-notification. This must be a written communication to our
          listed agent, satisfactory to us, and must include:
        </p>

        <ol type="i">
          <li>Your physical or electronic signature;</li>
          <li>
            Identification of the removed material and its prior location;
          </li>
          <li>
            A statement under penalty of perjury that the removal was a result
            of mistake or misidentification;
          </li>
          <li>
            Your name, address, telephone number, email address, and a statement
            consenting to jurisdiction;
          </li>
          <li>
            A statement that you will accept service of process from the
            purported copyright owner or its agent.
          </li>
        </ol>

        <h2>11. Modification of These Terms</h2>

        <p>
          a. We retain the right to modify these Terms at any time by posting
          amended Terms to the Service. No additional notification will be
          provided about amendments. YOU ACKNOWLEDGE THAT YOUR CONTINUED USE OF
          THE SERVICE FOLLOWING SUCH AMENDMENTS WILL CONSTITUTE YOUR ACCEPTANCE
          OF THEM, REGARDLESS OF WHETHER YOU HAVE ACTUALLY READ THEM.
        </p>

        <h2>12. Indemnification and Release</h2>

        <p>
          a. By using the Service, you agree to indemnify us and hold us
          harmless from any damages and third-party claims, including attorney’s
          fees, arising from your use of the Service or breach of these Terms.
        </p>

        <p>
          b. In case of disputes with other users or third parties, you release
          us, our officers, employees, agents, and successors-in-right from all
          claims, demands, and damages of every kind, known or unknown,
          suspected or unsuspected, disclosed or undisclosed, related to such
          disputes and/or the Service.
        </p>

        <h2>13. Disclaimer of Warranties and Limitations of Liabilities</h2>

        <p>
          <strong>
            READ THIS SECTION CAREFULLY AS IT LIMITS OUR LIABILITY TO THE
            MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
          </strong>
        </p>

        <p>
          b. The Service may include links to third-party websites or other
          services independent of us. We take no responsibility for the content,
          privacy policies, or practices of such third-party entities and make
          no representation or warranty about the accuracy, completeness, or
          authenticity of information on these websites or services. We disclaim
          liability for any and all consequences arising from your use of such
          third-party websites or services.
        </p>

        <p>
          c. The Service is provided "AS-IS" without any warranty or condition,
          express, implied, or statutory. We disclaim all implied warranties,
          including merchantability, fitness for a particular purpose,
          non-infringement, information accuracy, integration, interoperability,
          or quiet enjoyment. We also disclaim any warranties for viruses or
          other harmful components in connection with the Service.
        </p>

        <p>
          d. UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (EVEN IF
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM ANY ASPECT
          OF YOUR USE OF THE SERVICE, INCLUDING, WITHOUT LIMITATION, YOUR USE,
          MISUSE, OR INABILITY TO USE THE SERVICE, RELIANCE ON ANY CONTENT,
          INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION, OR COMPLETE
          DISCONTINUANCE OF THE SERVICE, OR THE TERMINATION OF SERVICE BY US.
          THESE LIMITATIONS ALSO APPLY TO DAMAGES INCURRED BY REASON OF OTHER
          SERVICES OR PRODUCTS RECEIVED OR ADVERTISED IN CONNECTION WITH THE
          SERVICE.
        </p>

        <p>
          e. WE DO NOT WARRANT THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS
          OR EXPECTATIONS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
          SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM
          YOUR USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY
          OF ANY PRODUCTS, SERVICES, INFORMATION, CONTENT, OR OTHER MATERIAL
          OBTAINED THROUGH THE SERVICE WILL MEET YOUR REQUIREMENTS OR
          EXPECTATIONS, OR (v) ANY ERRORS IN CONTENT WILL BE CORRECTED.
        </p>

        <p>
          f. ANY CONTENT OBTAINED THROUGH THE USE OF THE SERVICE IS AT YOUR OWN
          DISCRETION AND RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA RESULTING FROM SUCH
          CONTENT.
        </p>

        <p>
          g. YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION
          WITH THE SERVICE OR ANY OTHER GRIEVANCE SHALL BE THE TERMINATION OF
          YOUR USE OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, IN NO CASE
          SHALL OUR MAXIMUM LIABILITY ARISING FROM OR RELATING TO YOUR USE OF
          THE SERVICE EXCEED $1.
        </p>

        <p>
          h. All the above disclaimers of warranties and limitations of
          liabilities apply to our parent companies, subsidiaries, directors,
          officers, employees, agents, designees, contractors, affiliates,
          subsidiaries, successors, and assigns as well.
        </p>
        <h2>14. Disputes</h2>

        <p>
          a. To the maximum extent permitted by law, these Terms, as well as any
          claim, cause of action, or dispute between you and us, are governed by
          the laws of the Russian Federation without regard to conflict of law
          provisions. FOR ANY CLAIM BETWEEN US, YOU AGREE TO SUBMIT AND CONSENT
          TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE
          OF THE COURTS IN the Russian Federation. However, in the event that we
          are seeking indemnification from you hereunder, we may file suit for
          indemnification (and any other claims) in the same court in which the
          claim against us for which we are seeking indemnification is brought.
          You hereby waive any right to seek another venue because of improper
          or inconvenient forum.
        </p>

        <p>
          b. YOU AGREE THAT YOU MAY BRING CLAIMS ONLY IN YOUR INDIVIDUAL
          CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
          OR REPRESENTATIVE ACTION.
        </p>

        <p>
          c. You hereby agree that as part of the consideration for these terms,
          you are hereby waiving any right you may have to a trial by jury for
          any dispute between us arising from or relating to these terms or the
          Service. This provision shall be enforceable even in the case that any
          arbitration provisions or any other provisions of this section are
          waived.
        </p>

        <h2>15. General Terms</h2>

        <p>
          (a) These Terms, as amended from time to time, constitute the entire
          agreement between you and us and supersede all prior agreements
          between you and us and may not be modified without our written
          consent.
        </p>

        <p>
          (b) Our failure to enforce any provision of these Terms will not be
          construed as a waiver of any provision or right.
        </p>

        <p>
          (c) If any part of these Terms is determined to be invalid or
          unenforceable pursuant to applicable law, then the invalid and
          unenforceable provision will be deemed superseded by a valid,
          enforceable provision that most closely matches the intent of the
          original provision and the remainder of the agreement shall continue
          in effect.
        </p>

        <p>
          (d) Nothing herein is intended, nor will be deemed, to confer rights
          or remedies upon any third party.
        </p>

        <p>
          (e) These Terms are not assignable, transferable or sub-licensable by
          you except with our prior written consent, but may be assigned or
          transferred by us without restriction.
        </p>

        <p>
          (f) You agree that we may provide you with notices by e-mail, regular
          mail, or postings to the Service.
        </p>

        <p>
          (g) The section titles in these Terms are for convenience only and
          have no legal or contractual effect.
        </p>

        <p>
          (h) As used in these Terms, the term “including” is illustrative and
          not limitative.
        </p>

        <p>
          (i) If this agreement is translated and executed in any language other
          than English and there is any conflict as between the translation and
          the English version, the English version shall control.
        </p>
      </div>
    </TosWrapper>
  );
};

const TosWrapper = styled.div`
  max-height: 100vh;
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  .content {
    padding: 30px;
    overflow-y: auto;
  }
  * {
    font-family: Montserrat, sans-serif !important;
  }
  @media only screen and (max-width: 1100px) {
  }
`;
