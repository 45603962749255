import { styled } from "styled-components";
import {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  DragEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import { CHAT_BUTTON_SIZE, CHAT_INPUT_HEIGHT } from "Helpers/Constants";
import { RiLoopRightFill } from "react-icons/ri";
import { MdDriveFolderUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { checkIfValidFile } from "Helpers/functions";

interface ChatFileInputProps {
  fileType?: string;
  userFile: File | null | undefined;
  setUserFile: Dispatch<SetStateAction<File | null | undefined>>;
  uploadUserFile: () => void;
  restart: () => void;
}
export const ChatFileInput = ({
  fileType = "audio/*,video/*,text/*",
  userFile,
  setUserFile,
  uploadUserFile,
  restart,
}: ChatFileInputProps) => {
  const { t } = useTranslation();
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const [isValidFile, setIsValidFile] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (userFile) {
      setIsValidFile(checkIfValidFile(userFile.name, fileType));
    } else if (fileInputRef?.current?.value) {
      fileInputRef!.current!.value = "";
    }
  }, [userFile, fileType]);

  const resetInput = () => {
    fileInputRef!.current!.value = "";
    restart();
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setUserFile(files[0]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      setUserFile(files[0]);
    }
  };

  return (
    <ChatFileInputWrapper>
      <div
        className={`drag-area ${isDraggingOver ? "drag-over" : ""} ${
          userFile ? "has-file" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <label htmlFor="file-input" className="file-input">
          {!userFile ? t(`convert-file.drag-choose`) : userFile?.name}
        </label>
        <input
          ref={fileInputRef}
          accept={fileType}
          id="file-input"
          disabled={!!userFile}
          type="file"
          onChange={handleFileChange}
          placeholder={t("general.your-message")}
        />
      </div>
      {userFile && (
        <>
          <button
            className="send-button"
            onClick={uploadUserFile}
            disabled={!userFile || !isValidFile}
          >
            <MdDriveFolderUpload
              className="send"
              size={CHAT_BUTTON_SIZE / 2}
              color="white"
              fill="white"
            />
          </button>
          <button onClick={resetInput}>
            <RiLoopRightFill
              className="loop"
              size={CHAT_BUTTON_SIZE / 2}
              color="white"
              fill="white"
            />
          </button>
        </>
      )}
    </ChatFileInputWrapper>
  );
};

const ChatFileInputWrapper = styled.section`
  height: ${CHAT_INPUT_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--white);
  z-index: 1;
  padding: 5px 20px;
  .has-file {
    background-color: grey;
    color: white;
  }
  .drag-area {
    height: ${CHAT_INPUT_HEIGHT}px;
    border: 5px dashed var(--grey);
    max-height: 90%;
    outline: 0;
    margin: 0;
    flex: 1;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &.drag-over {
      border-color: var(--primary);
    }
    &:active {
      border: 5px dashed var(--primary);
    }

    input {
      display: none;
    }
    .file-input {
      height: ${CHAT_INPUT_HEIGHT}px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  button {
    cursor: pointer;
    border: none;
    border-radius: 100%;
    background-color: var(--primary);
    flex-shrink: 0;
    flex-grow: 0;
    width: ${CHAT_BUTTON_SIZE}px;
    height: ${CHAT_BUTTON_SIZE}px;
    margin: 0;
    padding: 0;
    &:disabled {
      background-color: var(--dark-grey);
    }
    svg {
      transform: translate(0, 1px);
    }
  }
  @media only screen and (max-width: 1100px) {
    button {
      .loop {
        svg {
          transform: translate(1px, 2px);
        }
      }
      .send {
        svg {
          transform: translate(2px, 3px);
        }
      }
    }
  }
`;
